<template>
  <b-card>
    <div class="d-flex flex-wrap">
      <div
        class="buttons-header mb-1"
      >
        <button
          class="compact ui button btn-grey text-height"
          @click="refreshPage"
        >
          <refresh-cw-icon
            size="12"
            class="custom-class line-top"
            :stroke-width="3"
          />
          Tải lại
        </button>
        <button
          v-if="checkQuyenCapNhatLichLamViec"
          class="compact ui btn-primary button text-height"
          @click="showModalTaoLichLamViec"
        >
          <edit-icon
            size="12"
            class="custom-class line-top"
            :stroke-width="3"
          />
          Cập nhật lịch làm việc
        </button>
        <button
          v-if="checkQuyenThemMoi"
          class="compact ui btn-primary button text-height"
          @click="showModalThemNgayNghi"
        >
          <plus-icon
            size="12"
            class="custom-class line-top"
            :stroke-width="3"
          />
          Thêm ngày nghỉ
        </button>
        <button
          v-if="checkQuyenXoa"
          class="compact ui btn-primary button text-height"
          :class="{disabled : selectedNumber === 0}"
          @click="$refs.confirmDelete.showModal()"
        >
          <trash-2-icon
            size="12"
            class="custom-class line-top"
            :stroke-width="3"
          />
          Xóa
        </button>
      </div>
    </div>
    <good-table
      ref="thoiGianLamViec"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="danhSachNgayLamViec"
      :total="soLuongNgayLamViec"
      @selected-item="onSelectRow"
      @column-filter="filterDanhSachNgayLamViec()"
      @page-change="pageChange"
      @update-item="onUpdate"
      @delete-item="setItemUpdate"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <span v-if="props.props.column.field === 'ngay'">
          {{ formatDate(props.props.row.ngay) }}
        </span>
        <div
          v-else-if="props.props.column.field === 'laNgayNghi'"
          class="custom-checkbox-position"
        >
          <b-form-checkbox
            v-model="props.props.row.laNgayNghi"
            disabled=""
          />
        </div>
        <div
          v-else-if="props.props.column.field === 'thoiGianBatDauSang'"
          class="text-center"
        >
          {{ `${props.props.row.thoiGianBatDauSang} - ${props.props.row.thoiGianKetThucSang}` }}
        </div>
        <div
          v-else-if="props.props.column.field === 'thoiGianKetThucChieu'"
          class="text-center"
        >
          {{ `${props.props.row.thoiGianBatDauChieu} - ${props.props.row.thoiGianKetThucChieu}` }}
        </div>
      </template>
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <date-picker
          v-if="props.props.column.field === 'ngay'"
          v-model="dateFilter"
          range
          class="w-100"
          placeholder="Chọn ngày"
          format="DD/MM/YYYY"
          @change="filterDanhSachNgayLamViec()"
        />
        <treeselect
          v-else-if="props.props.column.field === 'laNgayNghi'"
          v-model="filter.laNgayNghi"
          v-format-v-select
          class="vtreeselect-chooser"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :options="options"
          placeholder="Tìm kiếm"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
      </template>
      <template
        slot="actions"
        slot-scope="props"
      >
        <div
          v-if="props.props.column.field === 'actions'"
          class="d-flex justify-content-center"
        >
          <edit-icon
            v-if="checkQuyenCapNhatNgayNghi"
            v-b-tooltip.hover.v-secondary
            title="Cập nhật ngày nghỉ"
            size="16"
            class="custom-class cursor-pointer mr-1"
            @click="onUpdate(props.props.row)"
          />
          <trash-2-icon
            v-if="checkQuyenXoa"
            v-b-tooltip.hover.v-secondary
            title="Xoá"
            size="16"
            class="custom-class cursor-pointer"
            @click="setItemUpdate(props.props.row)"
          />
        </div>
      </template>
    </good-table>
    <common-modal
      ref="taoLichLamViec"
      :title="title"
      size="xl"
      @handle-ok="onSave"
      @reset-form="resetFormNgayLamViec"
      @handle-focus="handleFocusError"
    >
      <b-row class="mb-2 mt-1">
        <b-col
          md="4"
          sm="6"
        >
          <b-form-group
            label="Từ ngày"
          >
            <validation-provider
              v-slot="{ errors }"
              key="tuNgay"
              :rules="{ required: true }"
              name="tuNgay"
            >
              <date-picker
                id="tuNgay"
                ref="tuNgay"
                v-model="form.tuNgay"
                placeholder="Chọn từ ngày"
                class="w-100"
                :class="{required: errors.length > 0}"
                format="DD/MM/YYYY"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <b-form-group
            label="Đến ngày"
          >
            <validation-provider
              v-slot="{ errors }"
              key="denNgay"
              :rules="{ required: true }"
              name="denNgay"
            >
              <date-picker
                id="denNgay"
                ref="denNgay"
                v-model="form.denNgay"
                class="w-100"
                format="DD/MM/YYYY"
                :class="{required: errors.length > 0}"
                placeholder="Chọn đến ngày"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <ve-table
        border-y
        style="width:100%"
        class="ve-custom-header"
        :columns="taoLichColumns"
        :table-data="taoLichRows"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.taoLichLamViec.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="cancelModalTaoLich"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="themMoiNgayNghi"
      title="Thêm mới ngày nghỉ"
      @handle-ok="themNgayNghi(true)"
      @reset-form="resetFormNgayNghi"
      @handle-focus="handleFocusError"
    >

      <b-form>
        <b-form-group
          label="Ngày nghỉ"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="ngayNghi"
            :rules="{ required: true }"
            name="ngayNghi"
          >
            <date-picker
              id="picker"
              ref="ngayNghi"
              v-model="ngayNghi.ngayNghi"
              class="w-100"
              range
              format="DD/MM/YYYY"
              placeholder="Chọn ngày nghỉ"
              :class="{required: errors.length > 0}"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Ghi chú"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="ghiChu"
            :rules="{ required: true }"
            name="ghiChu"
          >
            <b-form-textarea
              id="donVi"
              ref="ghiChu"
              v-model="ngayNghi.mota"
              placeholder="Nhập ghi chú"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.themMoiNgayNghi.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="capNhatNgayNghi"
      title="Cập nhật ngày nghỉ"
      @handle-ok="themNgayNghi(false)"
      @reset-form="resetFormNgayNghi"
      @handle-focus="handleFocusError"
    >
      <b-form>
        <b-form-group
          label="Ngày"
          class="required"
        >
          <date-picker
            id="picker"
            ref="pickerNgayNghi"
            v-model="ngayNghi.ngayNghi"
            class="w-100"
            format="DD/MM/YYYY"
            :disabled="true"
            placeholder="Chọn ngày nghỉ"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Ngày nghỉ"
          label-for="input-1"
        >
          <b-form-checkbox
            v-model="ngayNghi.status"
            switch
          />
        </b-form-group>
        <b-form-group
          label="Ghi chú"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="ghiChu"
            :rules="{ required: true }"
            name="ghiChu"
          >
            <b-form-textarea
              ref="ghiChu"
              v-model="ngayNghi.mota"
              :class="{required: errors.length > 0}"
              placeholder="Nhập ghi chú"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.capNhatNgayNghi.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonnModal.hideModal(),$refs.themMoiNgayNghi.hideModal() , $refs.taoLichLamViec.hideModal() , $refs.capNhatNgayNghi.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  // BFormInput,
  BFormCheckbox,
  BForm,
  BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import GoodTable from '@/components/GoodTable.vue'
import END_POINTS from '@/api/endpoints'
import _cloneDeep from 'lodash/cloneDeep'
import {
  EditIcon, Trash2Icon, RefreshCwIcon, PlusIcon,
} from 'vue-feather-icons'
import { compareObjects, formatDate, formatDateRequest } from '@/utils/index'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
// import $ from 'jquery'
import { checDeleteMultiple } from '../../../../utils/common-utils'

const DEFAULT_LIST_DATE = [
  {
    name: 'Thứ 2',
    thu: 1,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Thứ 3',
    thu: 2,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Thứ 4',
    thu: 3,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Thứ 5',
    thu: 4,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Thứ 6',
    thu: 5,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Thứ 7',
    thu: 6,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
  {
    name: 'Chủ nhật',
    thu: 0,
    thoiGianBatDauSang: null,
    thoiGianKetThucSang: null,
    thoiGianBatDauChieu: null,
    thoiGianKetThucChieu: null,
  },
]

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    CommonModal,
    GoodTable,
    BRow,
    BCol,
    DatePicker,
    RefreshCwIcon,
    PlusIcon,
    BFormGroup,
    // BFormInput,
    BForm,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    EditIcon,
    Trash2Icon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      ocsen: false,
      isShow: false,
      isShowFooter: false,
      required,
      title: 'Cập nhật lịch làm việc',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '30px',
          field: 'STT',
        },
        {
          label: 'Ngày',
          field: 'ngay',
          width: '200px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Buổi sáng',
          field: 'thoiGianBatDauSang',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Buổi chiều',
          field: 'thoiGianKetThucChieu',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Là ngày nghỉ',
          field: 'laNgayNghi',
          tdClass: 'text-center',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'moTa',
          tdClass: 'text-left',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '80px',
        },
      ],
      options: [
        {
          id: true,
          label: 'Ngày nghỉ',
        },
        {
          id: false,
          label: 'Ngày làm việc',
        },
      ],
      taoLichColumns: [
        {
          field: 'name',
          key: 'a',
          title: 'Thứ',
          width: 200,
          align: 'left',
        },
        {
          title: 'Buổi sáng',
          children: [
            {
              field: 'thoiGianBatDauSang',
              key: 'b',
              title: 'Thời gian từ',
              width: 100,
              renderBodyCell: ({ row, column }) => <date-picker
                  v-model={row[column.field]}
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  disabled-time={this.disableTimeAM}
              />,
            },
            {
              field: 'thoiGianKetThucSang',
              key: 'c',
              title: 'Thời gian đến',
              width: 100,
              renderBodyCell: ({ row, column }) => <date-picker
                  v-model={row[column.field]}
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  disabled-time={this.disableTimeAM}
              />,
            },
          ],
        },
        {
          title: 'Buổi chiều',
          children: [
            {
              field: 'thoiGianBatDauChieu',
              key: 'd',
              title: 'Thời gian từ',
              width: 100,
              renderBodyCell: ({ row, column }) => <date-picker
                  v-model={row[column.field]}
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  disabled-time={this.disableTimePM}
              />,
            },
            {
              field: 'thoiGianKetThucChieu',
              key: 'e',
              title: 'Thời gian đến',
              width: 110,
              renderBodyCell: ({ row, column }) => <date-picker
                  v-model={row[column.field]}
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  disabled-time={this.disableTimePM}
              />,
            },
          ],
        },
      ],
      taoLichRows: _cloneDeep(DEFAULT_LIST_DATE),
      rowSelected: [],
      dateFilter: null,
      form: {
        tuNgay: null,
        denNgay: null,
      },
      ngayNghi: {
        mota: null,
        ngayNghi: null,
        status: null,
      },
      filter: {
        tuNgay: null,
        denNgay: null,
        laNgayNghi: null,
        pageNumber: 1,
        pageSize: 10,
      },
      beginObject: {},
      danhSachNgayLamViec: [],
      soLuongNgayLamViec: 0,
      selectedNumber: 0,
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.THOI_GIAN_LAM_VIEC.THEM_NGAY_NGHI])
    },
    checkQuyenCapNhatLichLamViec() {
      return hasPermission([PERMISSION_NAME.THOI_GIAN_LAM_VIEC.CAP_NHAT_LICH_LAM_VIEC])
    },
    checkQuyenCapNhatNgayNghi() {
      return hasPermission([PERMISSION_NAME.THOI_GIAN_LAM_VIEC.CAP_NHAT_NGAY_NGHI])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.THOI_GIAN_LAM_VIEC.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenCapNhatNgayNghi, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  mounted() {
  },
  created() {
    this.filterDanhSachNgayLamViec()
  },
  methods: {
    openModal() {
      if (compareObjects({
        mota: null,
        ngayNghi: null,
        status: null,
      }, this.ngayNghi)) {
        this.$refs.themMoiNgayNghi.hideModal()
        this.$refs.commonnModal.hideModal()
      } else if (compareObjects(this.beginObject, this.ngayNghi)) {
        this.$refs.commonnModal.hideModal()
        this.$refs.capNhatNgayNghi.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    cancelModalTaoLich() {
      if (
        compareObjects({
          tuNgay: null,
          denNgay: null,
        }, this.form)
      ) {
        this.$refs.taoLichLamViec.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    resetFormNgayNghi() {
      this.ngayNghi = {
        mota: null,
        ngayNghi: null,
        status: null,
      }
    },
    resetFormNgayLamViec() {
      this.form = {
        tuNgay: null,
        denNgay: null,
      }
      this.taoLichRows = _cloneDeep(DEFAULT_LIST_DATE)
    },
    formatDate,
    formatDateRequest,
    pageChange() {
      const data = this.$refs.thoiGianLamViec.getCurrentPage()
      this.payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.DANH_SACH, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.danhSachNgayLamViec = res.data.data
        }
      })
    },
    getDataDanhSachNgayLamViec() {
      const data = this.$refs.thoiGianLamViec.getCurrentPage()
      this.isLoading = false
      this.filter = {
        ...this.filter,
        tuNgay: this.dateFilter ? formatDateRequest(this.dateFilter[0]) : '',
        denNgay: this.dateFilter ? formatDateRequest(this.dateFilter[1]) : '',
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      try {
        this.$axios.post(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.DANH_SACH, this.filter, false).then(res => {
          if (res.data?.succeeded) {
            this.danhSachNgayLamViec = res.data.data
            this.soLuongNgayLamViec = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    filterDanhSachNgayLamViec() {
      this.filter = {
        laNgayNghi: this.filter.laNgayNghi,
        tuNgay: this.dateFilter ? formatDateRequest(this.dateFilter[0]) : null,
        denNgay: this.dateFilter ? formatDateRequest(this.dateFilter[1]) : null,
        pageNumber: 1,
        pageSize: this.filter.pageSize,
      }
      this.$axios.post(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.DANH_SACH,
        this.filter,
        false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachNgayLamViec = response.data.data
          this.soLuongNgayLamViec = response.data.totalCount || 0
          this.$refs.thoiGianLamViec.resetCurrentPage(this.filter.pageNumber)
        }
      })
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataDanhSachNgayLamViec()
      })
    },
    refreshPage() {
      this.filter = {
        tuNgay: null,
        denNgay: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.thoiGianLamViec.$refs.vbTables.reset()
      this.dateFilter = null
      this.getDataDanhSachNgayLamViec()
    },
    addCommom() {
      this.isShow = true
      this.$refs.commonModal.showModal()
    },
    onSelectRow(value) {
      this.rowSelected = value
      this.selectedNumber = value.length
    },
    showModalTaoLichLamViec() {
      this.isShow = true
      this.$refs.taoLichLamViec.showModal()
    },
    showModalThemNgayNghi() {
      this.isShow = true
      this.$refs.themMoiNgayNghi.showModal()
      // this.$nextTick(() => {
      //   this.$refs.ngayNghi.focus()
      // })
    },
    disableTimeAM(date) {
      const compareDate = new Date()
      compareDate.setHours(11)
      compareDate.setMinutes(59)
      return date > compareDate
    },
    disableTimePM(date) {
      const compareDate = new Date()
      compareDate.setHours(12)
      compareDate.setMinutes(0)
      return date < compareDate
    },
    themNgayNghi(isCreate) {
      let payload = {
        status: true,
        mota: this.ngayNghi.mota,
      }
      if (isCreate) {
        payload = {
          ...payload,
          tuNgay: this.formatDateRequest(this.ngayNghi.ngayNghi[0]),
          denNgay: this.formatDateRequest(this.ngayNghi.ngayNghi[1]),
        }
      } else {
        payload = {
          ...payload,
          tuNgay: this.formatDateRequest(this.ngayNghi.ngayNghi),
          denNgay: this.formatDateRequest(this.ngayNghi.ngayNghi),
          status: this.ngayNghi.status,
        }
      }
      this.$axios.post(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.THEM_NGAY_NGHI, payload).then(() => {
        this.filterDanhSachNgayLamViec()
        this.$refs.themMoiNgayNghi.hideModal()
        this.$refs.capNhatNgayNghi.hideModal()
      })
    },
    onSave() {
      const dateRange = {
        tuNgay: this.formatDateRequest(this.form.tuNgay),
        denNgay: this.formatDateRequest(this.form.denNgay),
      }
      const payload = {
        ...dateRange,
        danhSachNgay: this.taoLichRows.map(({ name, ...keepAttrs }) => keepAttrs),
      }
      this.$axios.post(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.THEM_MOI, payload).then(res => {
        if (res.data?.succeeded) {
          this.filterDanhSachNgayLamViec()
          this.$refs.taoLichLamViec.hideModal()
        }
      })
    },
    onDelete() {
      this.isShow = false
      const payload = {
        data: this.rowSelected.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.THOI_GIAN_LAM_VIEC_TRONG_TUAN.XOA, payload, false).then(res => {
        if (res.data?.succeeded) {
          if (this.rowSelected.length >= this.danhSachNgayLamViec.length && this.filter.pageNumber > 1) {
            this.filter.pageNumber -= 1
            this.$refs.thoiGianLamViec.resetCurrentPage(this.filter.pageNumber)
          }
          this.getDataDanhSachNgayLamViec()
          checDeleteMultiple(this, res.data)
        }
      })
    },
    setItemUpdate(item) {
      this.rowSelected = [item]
      this.$refs.confirmDelete.showModal()
    },
    onUpdate(item) {
      this.ngayNghi = {
        mota: item.moTa,
        ngayNghi: new Date(item.ngay),
        status: item.laNgayNghi,
      }
      this.$refs.capNhatNgayNghi.showModal()
      this.beginObject = _cloneDeep(this.ngayNghi)
      this.isShow = true
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.label-date {
  line-height: 34px;
  margin-right: 5px;
}

@media only screen and (min-width: 1300px) {
  .buttons-header {
    width: 50% !important;
  }

  .date-header {
    width: 50% !important;
    .date-filter {
      display: flex;
      justify-content: end;
    }
  }
}

.buttons-header {
  width: 100%;
}

.date-header {
  width: 60%;
}
</style>
