<template>
  <thoi-gian-lam-viec-trong-tuan />
</template>
<script>
import ThoiGianLamViecTrongTuan from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/ThoiGianLamViecTrongTuan.vue'

export default {
  components: {
    ThoiGianLamViecTrongTuan,
  },
}
</script>
